import { useCallback, useEffect, useRef, useState } from 'react';

import * as Twilio from 'twilio-video';

interface UseTwilioResult {
    intercoms: Twilio.Participant[]
    viewers: Twilio.Participant[]
}
type UseTwilio = (connect: boolean, token: string, options: Twilio.ConnectOptions) => UseTwilioResult
enum UseTwilioState {
    Ready,
    Connecting,
    Connected,
    ConnectingCancelled,
    Error,
}

const INITIAL_RESULT: UseTwilioResult = { intercoms: [], viewers: [] };

const useTwilio: UseTwilio = (publishing, token, options) => {
    const roomRef = useRef<Twilio.Room | null>(null);
    const stateRef = useRef<UseTwilioState>(UseTwilioState.Ready);
    const [participants, setParticipants] = useState<UseTwilioResult>(INITIAL_RESULT);

    const onRemoteConnect = (participant: Twilio.Participant) => {
        setParticipants((participants) => {
            if (participant.videoTracks.size) {
                return {
                    ...participants,
                    intercoms: [...participants.intercoms, participant],
                };
            } else {
                return {
                    ...participants,
                    viewers: [...participants.viewers, participant],
                };
            }
        });
    };

    const onRemoteDisconnect = (participant) => {
        setParticipants((participants) => ({
            viewers: participants.viewers.filter((p) => p !== participant),
            intercoms: participants.intercoms.filter((p) => p !== participant),
        }));
    };

    const connect = useCallback(() => {
        stateRef.current = UseTwilioState.Connecting;
        Twilio.connect(token, options)
            .then((room) => {
                if (stateRef.current === UseTwilioState.ConnectingCancelled) {
                    disconnect();
                    return;
                }
                stateRef.current = UseTwilioState.Connected;
                roomRef.current = room;

                room.on('participantConnected', onRemoteConnect);
                room.on('participantDisconnected', onRemoteDisconnect);

                room.participants.forEach((participant) => onRemoteConnect(participant));
            })
            .catch(() => {
                // TODO: provide a way to recover from this state
                stateRef.current = UseTwilioState.Error;
            });
    }, [options, token]);

    const disconnect = () => {
        roomRef.current?.localParticipant?.tracks?.forEach?.((trackPublication) => {
            if (trackPublication.track instanceof Twilio.LocalDataTrack) {
                return;
            }
            trackPublication.track.stop();
        });
        roomRef.current?.disconnect();
        roomRef.current = null;
        stateRef.current = UseTwilioState.Ready;
        setParticipants(INITIAL_RESULT);
    };

    useEffect(() => {
        if (publishing && stateRef.current === UseTwilioState.Ready) {
            connect();
        } else if (!publishing && stateRef.current === UseTwilioState.Connected) {
            disconnect();
        } else if (!publishing && stateRef.current === UseTwilioState.Connecting) {
            // Can't cancel it through the API, so simply immediately disconnect after connecting.
            stateRef.current = UseTwilioState.ConnectingCancelled;
        }
    }, [publishing]);

    // Disconnect after unmount of components calling the hook
    useEffect(() => {
        return () => disconnect();
    }, []);

    return participants;
}

export default useTwilio;