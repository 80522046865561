import React, { useRef } from 'react';

const API_BASE_URL = 'https://api.hackercom.nl/api/v1';

const getUrl = (path) => `${API_BASE_URL}${path}`;

export interface Person {
    name: string
    directions: string
    slackKeys: string[]
};

export type Company = Person & {logoUrl: string};


interface NameResponse {
    name: string
    directions: string
    slack_keys: string
};

interface LogoResponse {
    name: string
    directions: string
    slack_keys: string
    image_url: string
};

interface IntercomResponse {
    names: NameResponse[]
    logos: LogoResponse[]
};

export const getCompaniesPersons: () => Promise<[Company[], Person[]]> = async () => {
    const response = await fetch(getUrl('/intercom'));

    const intercomJson: IntercomResponse = await response.json();
    const persons: Person[] = intercomJson.names.map((person) => ({
        name: person.name,
        directions: person.directions,
        slackKeys: person.slack_keys.split(','),
    }));
    const companies: Company[] = intercomJson.logos.map((person) => ({
        name: person.name,
        directions: person.directions,
        logoUrl: person.image_url,
        slackKeys: person.slack_keys.split(','),
    }));

    return [companies, persons];
}

const WS_URL = 'wss://api.hackercom.nl/cable';

export type ControlSocketOnMessage = (msg: object) => void;
export type ControlSocketOnClose = () => void;
export type UseControlSocketHook = (onMessage: ControlSocketOnMessage) => React.MutableRefObject<WebSocket>

export const useControlSocket: UseControlSocketHook = (onMessage) => {
    const onClose = () => {
        setTimeout(() => {
            wsRef.current = connectControlSocket(onMessage, onClose);
        }, 2500);
    };

    const wsRef = useRef<WebSocket | null>(null);
    wsRef.current ??= connectControlSocket(onMessage, onClose)

    return wsRef as React.MutableRefObject<WebSocket>;
}

type ConnectControlSocket = (onMessage: ControlSocketOnMessage, onClose: ControlSocketOnClose) => WebSocket;
const connectControlSocket: ConnectControlSocket = (onMessage, onClose) => {
    const ws = new WebSocket(WS_URL, ['actioncable-v1-json', 'actioncable-unsupported']);

    ws.onopen = function() {
        const msg = {
            command: 'subscribe',
            identifier: JSON.stringify({
                channel: 'IntercomChannel',
            }),
        };
        ws?.send(JSON.stringify(msg));
    };

    ws.onmessage = function(e) {
        const json = JSON.parse(e.data);
        if (json?.message) {
            onMessage(json.message);
        }
    };

    ws.onclose = function(e) {
        onClose();
    };

    ws.onerror = function(err) {
        ws?.close();
    };

    return ws;
}


export const requestOpenDoor: (callId: string) => void = async (callId) => {
    await fetch(getUrl('/unlocks'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ call: { id: callId } }),
    });
};

interface GetCallResponse {
    callId: string
    unlockUrl: string
}
export const getCall: (callId: string) => Promise<GetCallResponse> = async (callId) => {
    const response = await fetch(getUrl(`/calls/${callId}`)).then((response) => response.json());

    return {
        callId: response.id,
        unlockUrl: response.unlock_url,
    };
};

interface StartCallResponse {
    callId: string
    twilioToken: string
}
export const startCall: (person: Person) => Promise<StartCallResponse> = async (person) => {
    const destination: NameResponse = {
        name: person.name,
        slack_keys: person.slackKeys.join(','),
        directions: person.directions,
    };

    const response = await fetch(getUrl('/calls'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ destination }),
    }).then((response) => response.json());

    return {
        callId: response.id as string,
        twilioToken: response.twilio_participant_token as string,
    };
};

export const cancelCall: (callId: string) => void = (callId) => {
    fetch(getUrl(`/calls/${callId}`), { method: 'DELETE' });
}