import { useEffect, useState } from 'react';

import * as Twilio from 'twilio-video';

interface UseTwilioTracksResult {
    videoTrack: Twilio.VideoTrack | null
    audioTrack: Twilio.AudioTrack | null
}

type UseTwilioTracks = (participant: Twilio.RemoteParticipant) => UseTwilioTracksResult

const INITIAL_RESULT = { videoTrack: null, audioTrack: null };

const pubsToTracks = (trackMap: Map<string, Twilio.VideoTrackPublication | Twilio.AudioTrackPublication>) => {
    return Array.from(trackMap.values())
        .map(publication => publication.track)
        .filter((track) => track !== null) as Twilio.VideoTrack[] | Twilio.AudioTrack[];
}

const useTwilioTracks: UseTwilioTracks = (participant) => {
    const [tracks, setTracks] = useState<UseTwilioTracksResult>(INITIAL_RESULT);
    useEffect(() => {
        participant.on('trackSubscribed', (track) => {
            if (track.kind === 'video') {
                setTracks((tracks) => ({
                    ...tracks,
                    videoTrack: track as Twilio.VideoTrack,
                }));
            } else if (track.kind === 'audio') {
                setTracks((tracks) => ({
                    ...tracks,
                    audioTrack: track as Twilio.AudioTrack,
                }));
            }
        });
        participant.on('trackUnsubscribed', (track) => {
            if (track.kind === 'video') {
                setTracks((tracks) => ({
                    ...tracks,
                    videoTrack: null,
                }));
            } else if (track.kind === 'audio') {
                setTracks((tracks) => ({
                    ...tracks,
                    audioTrack: null,
                }));
            }
        });
        setTracks({
            videoTrack: (pubsToTracks(participant.videoTracks)[0] as Twilio.VideoTrack) ?? null,
            audioTrack: (pubsToTracks(participant.audioTracks)[0] as Twilio.AudioTrack) ?? null,
        });

        return () => {
            participant.removeAllListeners();
            setTracks(INITIAL_RESULT);
        }
    }, [participant]);

    return tracks;
};

export default useTwilioTracks;
